<template>
	<page-container
		:page="page"
		:class="{
			'has-landscape-video': page.vimeoIDLandscape,
			'has-portrait-video': page.vimeoIDPortrait,
		}"
	>
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>

		<video-header v-if="page.vimeoIDLandscape" :video="page.vimeoIDLandscape" type="landscape" />

		<video-header v-if="page.vimeoIDPortrait" :video="page.vimeoIDPortrait" type="portrait" />

		<main-content
			v-if="!page.introblock"
			:title="page.title || page.header"
			:content="page.content"
			:intro-image="{
				image: page.introductionImage,
				imageWebp: page.introductionImageWebp,
			}"
		/>

		<intro-block v-if="page.introblock" :item="page.introblock" />

		<usp-block
			v-if="page.uSPBlock"
			:header="page.uSPBlock.header"
			:items="page.uSPBlock.items"
			:button="{
				text: page.uSPBlock.button,
				link: page.uSPBlock.buttonLink,
			}"
		/>

		<contentblocks :items="page.contentblocks" />

		<frequently-asked-questions v-if="page.faqs && page.faqs.length" :items="page.faqs" />

		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();

await fetchPage();
</script>
